$(document).on('click', '.lu-tag:not(.active)', function(){
    var elem    = $(this),
        type    = elem.data('type'),
        wrapper = $('.last-updates'),
        list    = $('.lu-content .line');

    elem.addClass('active');
    elem.siblings().removeClass('active');
    
    wrapper.addClass('loading');
    requestAjax('last_updates', type, function(res){
        wrapper.removeClass('loading');
        list.html(res);
    });
})

$(document).on('click', '.change-tails', function(){
    $('.news-tail').toggleClass('tall');
});