$(document).on('click', '.period-item', function(){
    var elem = $(this),
        siblings = elem.siblings(),
        period = elem.data('period'),
        wrapper = $('.history-body');

    if(!elem.hasClass('active')){
        siblings.removeClass('active');
        elem.addClass('active');
        wrapper.addClass('loading');

        requestAjax('get_tracks', period, function(res){
            wrapper.find('.tracks-list').remove();
            wrapper.prepend(res);
            wrapper.removeClass('loading');
        });

    }
});

// function startDatePick(){

//     if($('.custom-period').length){
//         var options = {
//             showAnim: 'fadeIn',
//             dateFormat: 'dd.mm.yy',
//             prevText: '<span class="icon-left"></span>',
//             nextText: '<span class="icon-right"></span>',
//             monthNames: ['Январь' , 'Февраль' , 'Март' , 'Апрель' , 'Май' , 'Июнь' , 'Июль' , 'Август' , 'Сентябрь' , 'Октябрь' , 'Ноябрь' , 'Декабрь'],
//             dayNames: [ "Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота" ],
//             dayNamesMin: [ "Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб" ],
//             showOtherMonths: true,
//             onSelect: function(date, ui){
//                 ui.input.each(inputCheck);
//             }
//         }
        
//         $('.custom-period #date-from').datepicker(options,{
//             defaultDate: -1
//         });
        
//         $('.custom-period #date-to').datepicker(options,{
//             defaultDate: 1
//         });
//     }

// }

// startDatePick();

// $(document).on('click', '.send-period', function(){
//     var form = $('.custom-period'),
//         inputs = form.find('input'),
//         from = $('#date-from').val(),
//         to = $('#date-to').val(),
//         total = from + ';' + to,
//         period = $('.period-item'),
//         wrapper = form.parents('.default-block'),
//         block = wrapper.find('.history-body');

//     inputs.each(inputCheck);
    
//     if(!form.hasClass('error')){
//         wrapper.addClass('loading');
//         period.removeClass('active');

//         requestAjax('get_tracks', total, function(res){
//             block.find('.tracks-list').remove();
//             block.prepend(res);
//             wrapper.removeClass('loading');
//         });

//     }

// });