var current_page = window.location.href,
    to_cur = false;

function updateNav(){
    var url = window.location.href,
        nav = $('.site-menu li');

    nav.removeClass('current_page_item');

    nav.each(function(){
        var elem = $(this),
            link = elem.find('a'),
            link = link.attr('href');

        if(url == link){
            elem.addClass('current_page_item');
        }
    })
}

updateNav();

barba.init({
    timeout: 5000,
    transitions: [{
        name: 'Amazing transition',
        enter(data) {
        
            if(radio_status){
                $('.play-radio').addClass('playing');
                rotatePlayer();
            }

            $('body, html').scrollTop(0);
        },
        after(){
            phone_mask();
            recSlider();
            pasteMap();
            updateNav();
            hScroll();
            $('.mobile-menu').fadeOut(300);
        }
    }
    ],
    preventRunning: true,
    prevent: ({ el, event, href }) => {
        var result = false;

        $.each(event.path, function(index, item){
            if(item.id == 'wpadminbar'){
                result = true;
            }
        });

        if(href == window.location.href){
            event.preventDefault();
            event.stopPropagation();
            
            result = true;
        }

        href = href.split('.')
        href = href[href.length - 1]
        href = href.toLowerCase()

        var files = ['pdf', 'jpg', 'jpeg', 'docx', 'png']

        if(files.indexOf(href) != -1){
            result = true
            el.target = '_blank'
        }

        return result;
    }
});