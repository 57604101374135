$(document).on('click', '.anchor', function(e){
    var elem = $(this),
        href = elem.attr('href'),
        target = $(href);

    e.preventDefault();
    scrollTo(target);
})

function scrollTo(elem){
    var header = $('.site-header').height(),
        offset = elem.offset().top - header;

    $('html, body').stop().animate({
        scrollTop: offset
    }, 600);
}

$('#to_up').click(function(){
    $('html, body').stop().animate({
        scrollTop: 0
    }, 700);
})

function toUpAnchor(){
    var height = $(window).height() * 0.65;

    if($(window).scrollTop() > height){
        $('#to_up').addClass('show');
    }else{
        $('#to_up').removeClass('show');
    }
}

toUpAnchor();

$(window).scroll(toUpAnchor);