var radio_status = false;

$('.volume-line').slider({
    range: 'min',
    min: 0,
    max: 100,
    value: 100,
    slide: function( event, ui ) {
        var player = document.getElementById("player"),
            value = ui.value,
            volume = value * 0.01;

        if(value == 0){
            $('.player-content .change-volume i').addClass('mute');
        }else{
            $('.player-content .change-volume i').removeClass('mute');
        }

        $('.volume-line').slider( "value", value );

        player.volume = volume;

    }
});

$('.change-volume i').click(function(){
    var elem = $(this),
        player = document.getElementById("player"),
        volume = player.volume;

    if(volume != 0){
        $('.icon.change-volume i').addClass('mute');
        $('.volume-line').slider( "value", 0 );
        player.volume = 0;
    }else{
        $('.icon.change-volume i').removeClass('mute');
        $('.volume-line').slider( "value", 100 );
        player.volume = 1;
    }
})

function rotatePlayer(){
    var circle = $('.disk');

    if (circle === void(0)) {
        circle = $({deg: 0});
    }

    return circle.animate(
        {deg: 360},
        {
            duration: 12000,
            easing: 'linear',
            step: function(now){
                circle.css({transform: 'rotate(' + now + 'deg)'});
            },
            complete: function(){
                circle.get(0).deg = 0;
                rotatePlayer();
            },
        }
    );

}

function stopRotatingPlayer(){
    var circle = $('.disk');

    circle.stop();
    rotatePlayer().stop();
}

function playMusic(){
    var player = document.getElementById("player");
    
    radio_status = true;
    $('.play-radio').addClass('playing');
    rotatePlayer();
    player.play();
}

function stopMusic(){
    var player = document.getElementById("player");
    
    radio_status = false;
    player.pause();
    $('.play-radio').removeClass('playing');
    stopRotatingPlayer();
}

$(document).on('click', '.play-radio', function(){
    var elem = $(this);

    if(elem.hasClass('playing')){
        stopMusic();
    }else{
        playMusic();
    }

});

setInterval(function(){
    simpleAjax('get_playing', function(res){
        res = JSON.parse(res);

        var name = res.name,
            artist = res.artist,
            id = res.id,
            is_fav = res.is_fav,
            btn = $('.header-player .btns .add-to-fav'),
            played = res.played;

        $('.playing-author').html(artist);
        $('.playing-name').html(name);
        $('.last-played').find('.pl-item').remove();
        $('.last-played .last-played-content').append(played);

        if(is_fav){
            btn.addClass('added');
        }else{
            btn.removeClass('added');
        }

        btn.attr('data-id', id);

        if(res.image){
            $('.playing-now avatar-wrapper .avatar').text('').css('background-image', 'url(' + res.image + ')');
        }else{
            $('.playing-now .avatar-wrapper .avatar').text(res.artist.substr(0, 1)).css('background-image', '');
        }

    });
}, 5000);

$('.playing-now').click(function(){
    var elem = $(this),
        history = elem.find('.last-played');

    history.stop().fadeToggle(300);
    $(document).mouseup(function (e){
		if (!elem.is(e.target) && elem.has(e.target).length === 0) {
			history.stop().fadeOut(300);
		}
	});
})