$(document).on('click', '#subscribe_btn', function(e){
    var elem = $(this),
        input = $('#subscribe_email'),
        form = $('#subscribe_form');

    input.each(inputCheck);
    e.preventDefault();

    if(form.find('.error').length == 0){
        var val = $.trim(input.val());

        elem.attr('disabled', true);
        sendAjax('add_subscriber', input.val(), function(){
            input.val('');
            elem.attr('disabled', false);
            addNotif('Спасибо', 'Вы подписались на наши обновления', 'success');
        }, function(){
            input.val('');
            elem.attr('disabled', false);
            addNotif('Ошибка', 'Вы уже подписаны на нас', 'error');
        })
    }

})