function closeDropdowns(){
    $('.dropdown, .select-dropdown').removeClass('show');
}

function appendOptions(elem, list){
    elem[0].options = list;
}

function getOptions(elem, key){

    if(key){
        return elem[0].options[key];
    }else{
        return elem[0].options;
    }
    
}

function getOptionsList(elem, key, selected){
    var structure = '';

    if(key && key != 'all'){
        options = getOptions(elem, key);
    }else{
        options = getOptions(elem);
    }

    if(options){
        $.each(options, function(key, value){

            if(value.name && value.slug){

                if(selected && selected == key){
                    structure += '<li class="active" data-id="' + key + '" data-slug="' + value.slug + '">' + value.name + '</li>';
                }else{
                    structure += '<li data-id="' + key + '" data-slug="' + value.slug + '">' + value.name + '</li>';
                }
                    
            }else{

                if(selected && selected == key){
                    structure += '<li class="active" data-id="' + key + '">' + value + '</li>';
                }else{
                    structure += '<li data-id="' + key + '">' + value + '</li>';
                }

            }
            
        });   
    }

    return structure;
}

function startSelect(elem, select){
    var dropdown = elem.next('.select-dropdown'),
        options = getOptionsList(elem);

    dropdown.find('li:not(.clear)').remove();
    dropdown.prepend(options);
    elem.removeAttr('disabled data-id data-slug');
    elem.val('');

    if(select){
        console.log(dropdown.find('li[data-id="' + select + '"]'));
        dropdown.find('li[data-id="' + select + '"]').click();
    }
}

function resetSelect(elem, enable, selected){
    elem.removeAttr('data-id data-slug').val('');
    elem.next('.select-dropdown').find('li').removeClass('active');

    elem.parents('.form-group').removeClass('error success');

    if(enable){
        elem.removeAttr('disabled');
    }else{
        elem.attr('disabled', true);
    }

    if(selected && elem.hasClass('dynamic-list')){
        var dropdown = elem.next('.select-dropdown'),
            options = getOptionsList(elem, selected);

        dropdown.find('li:not(.clear)').remove();
        dropdown.prepend(options);

        if(!options) {
            elem.attr('disabled', true);
        }
    }

    if(elem.hasClass('parent')){
        var child = elem.data('child'),
            child = $(child);

        resetSelect(child, false);
    }
}

$(document).on('change', '.toggle-blocks input', function(){
    var elem = $(this),
        items = elem.parents('.toggle-blocks').find('input'),
        index = items.index(elem),
        parent = elem.parents('.toggle-parent'),
        items = parent.find('.toggle');

    items.removeClass('dn').hide();
    items.eq(index).show();
})

$(document).on('focus', '.select-wrapper input', function(){
    var elem = $(this),
        parent = elem.parent(),
        dropdown = parent.find('.select-dropdown');

    $('.select-dropdown').removeClass('current');
    dropdown.addClass('current');

    $('.select-dropdown:not(.current)').removeClass('show');

    dropdown.toggleClass('show');
    $(document).click(function (e){
        if (!parent.is(e.target) && parent.has(e.target).length === 0) {
            dropdown.removeClass('show current');
        }
    });
});

$(document).on('click', '.select-dropdown li', function(){
    var elem = $(this),
        text = elem.text(),
        id = elem.attr('data-id'),
        slug = elem.attr('data-slug'),
        wrapper = elem.parents('.select-wrapper'),
        dropdown = elem.parents('.select-dropdown'),
        form_group = elem.parents('.form-group'),
        input = wrapper.find('input');

    if(elem.hasClass('clear')){
        input.removeAttr('data-id data-slug').val('');
        elem.siblings().removeClass('active');
    }else{
        input.val(text).attr('data-id', id);

        if(slug){
            input.val(text).attr('data-slug', slug);
        }

        elem.addClass('active').siblings().removeClass('active');
    }

    if(input.hasClass('parent')){
        var child = input.data('child'),
            child = $(child);

        if(child.hasClass('show-all')){
            resetSelect(child, true, 'all');
        }else{
            resetSelect(child, true, id);
        }
        
    }

    dropdown.removeClass('show current');
    input.change();
});

$(document).on('change', '.form-group.mark input', function(){
    var elem = $(this),
        elem = elem.next(),
        active_index = elem.index(),
        siblings = elem.siblings('label');

    elem.addClass('active');

    siblings.each(function(){
        var elem = $(this),
            index = elem.index();

        if(index < active_index) {
            elem.addClass('active');
        }else{
            elem.removeClass('active');
        }
    })
});

$(document).on('click', '.toggler label:not([for])', function(){
    var checkbox = $(this).prev();
    checkbox.prop("checked", !checkbox.prop("checked"));
})

$(document).on('click', '.clone-input', function(e){
    e.preventDefault();

    var elem = $(this),
        max = elem.data('max'),
        parent = elem.parents('.form-group'),
        index = parent.attr('data-index');

    if(!index){
        index = 2;
    }else{
        index++;
    }
    
    parent.clone().insertAfter(parent);
    var clone = parent.next(),
        input = clone.find('input'),
        label = clone.find('label'),
        id = input.attr('id').replace(/[0-9]/gm, index);

    clone.attr('data-index', index);
    input.attr('id', id);
    label.attr('for', id);
    input.val('');

    if(index == max){
        clone.find('.clone-input').remove();
    }

    if(input.hasClass('phone-mask')){
        phone_mask();
    }
    elem.remove();
});