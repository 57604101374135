$(document).on('click', '.toggle-dropdown', function(){
    var elem = $(this),
        dropdown = elem.find('.d-dropdown, .def-dropdown');

    dropdown.toggleClass('show');

    $(document).click(function (e){
        if (!elem.is(e.target) && elem.has(e.target).length === 0) {
            dropdown.removeClass('show');
        }
    });

});

$(document).on('click', '.def-dropdown li', function(){
    var elem = $(this),
        text = elem.text(),
        id = elem.attr('data-id'),
        slug = elem.attr('data-slug'),
        wrapper = elem.parents('.select-wrapper'),
        dropdown = elem.parents('.def-dropdown'),
        form_group = elem.parents('.form-group'),
        input = wrapper.find('input');

    if(elem.hasClass('clear')){
        input.removeAttr('data-id data-slug').val('');
        elem.siblings().removeClass('active');
    }else{
        input.val(text).attr('data-id', id);

        if(slug){
            input.val(text).attr('data-slug', slug);
        }

        elem.addClass('active').siblings().removeClass('active');
    }

    if(input.hasClass('parent')){
        var child = input.data('child'),
            child = $(child);

        if(child.hasClass('show-all')){
            resetSelect(child, true, 'all');
        }else{
            resetSelect(child, true, id);
        }
        
    }

    input.change();
});