$(document).on('click', '.arrows[data-slider] div', function(){
    var elem = $(this),
        parent = elem.parent(),
        slider = parent.data('slider'),
        slider = $('.' + slider),
        index = elem.index()

    if(index){
        slider.slick('slickNext')
    }else{
        slider.slick('slickPrev')
    }
})

$('.main-news').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    infinite: false,
    mobileFirst: true,
    responsive: [
        {
            breakpoint: 767,
            settings: 'unslick'
        }
    ]
})