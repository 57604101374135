function phone_mask(){
    var check = $('.phone-mask').length;   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('phone-mask');

        Array.prototype.forEach.call(items, function (element) {
            var phoneMask = new IMask(element, {
                mask: '+{7} (000) 000-00-00',
                lazy: false,  // make placeholder always visible
                placeholderChar: '_'     // defaults to '_'
            });

            element.mask = phoneMask;
        });

        $('.phone-mask').each(function(){
            var elem = $(this);

            elem.attr('placeholder', elem.val());
        });

    }
    
}

function time_mask(){
    var check = $('.time-mask').length;   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('time-mask');

        Array.prototype.forEach.call(items, function (element) {
            var phoneMask = new IMask(element, {
                mask: 'HH:MM',
                lazy: false,  // make placeholder always visible
                placeholderChar: '_',
                blocks: {
                    HH: {
                        mask: IMask.MaskedRange,
                        from: 00,
                        to: 23
                    },
                    MM: {
                        mask: IMask.MaskedRange,
                        from: 00,
                        to: 59
                    }
                }
            });
        });

    }

}

function code_mask(){
    var check = $('.code-mask').length;   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('code-mask');

        Array.prototype.forEach.call(items, function (element) {
            var codeMask = new IMask(element, {
                mask: '0 0 0 0',
                lazy: false,  // make placeholder always visible
                placeholderChar: '_'     // defaults to '_'
            });
        });

        $('.code-mask').each(function(){
            var elem = $(this);

            elem.attr('placeholder', elem.val());
        });

    }
    
}

function priceMask(){
    var check = $('.price-mask').length;   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('price-mask');

        Array.prototype.forEach.call(items, function (element) {
            var numberMask = new IMask(element, {
                mask: Number,
                min: 0,
                max: 500000,
                thousandsSeparator: ' '
            });
        });

    }

}

function date_mask(){
    var items = document.getElementsByClassName('date-mask');

    Array.prototype.forEach.call(items, function (element) {
        var dateMask = new IMask(element, {
            mask: Date,
            lazy: false,
            placeholderChar: '_'     // defaults to '_'
        });
    });
}

$(document).ready(function () {
    
    phone_mask();
    priceMask();
    code_mask();
    time_mask();
    
    var check = $('.date-mask').length;   

    if ( check > 0 ) {

        var items = document.getElementsByClassName('date-mask');

        Array.prototype.forEach.call(items, function (element) {
            var dateMask = new IMask(element, {
                mask: Date,
                lazy: false,
                placeholderChar: '_'     // defaults to '_'
            });
        });


    }

    $(document).on('input', '.name-mask', function(){
        var elem = $(this),
            val  = elem.val().replace(/[^А-Яа-я-\s]/gi, ''),
            val  = val.substr(0, 24);        

        elem.val(val);
    });

});