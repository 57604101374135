$(document).on('click', '#map', function(){
    var elem = $(this);

    elem.addClass('show');
});

function pasteMap(){
    var map = $('#map');

    if(map.length){
        map.html('<script type="text/javascript" charset="utf-8" async src="https://api-maps.yandex.ru/services/constructor/1.0/js/?um=constructor%3A32ee00e0c868adf1878d14bbc8e3a37e2509e88a4da78d02926ed7d860db86df&amp;width=100%25&amp;height=400&amp;lang=ru_RU&amp;scroll=true"></script>');
    }

}

pasteMap();