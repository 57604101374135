$(document).on('click', '.yey', function(){
    $('#subs').addClass('active');
    $('#buttonss').addClass('active');
});

$(document).on('click', '.close-succes', function(){
    $('#succes-block').removeClass('active');
});

$(document).on('click', '.non', function(){
    $('#succes-block').removeClass('active');
});


// $( document ).ready(function() {
//     window.setTimeout(function(){$('#succes-block').addClass('active');},5000)
// });

