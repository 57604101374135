var tag = document.createElement('script');

tag.src = "https://www.youtube.com/iframe_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);


var player;
function onYouTubeIframeAPIReady() {
    player = new YT.Player('stream_player', {
        playerVars: {'rel': 0},
        height: '315',
        width: '560',
        videoId: '5qap5aO4i9A'
    });
}

var stream = $('.live-stream');

$('.toggle-stream').click(function(){
    
    if(stream.hasClass('show')){
        player.stopVideo();
    }else{
        player.playVideo();
    }

    stream.toggleClass('show');
});

$('.close-stream').click(function(){
    stream.removeClass('show');
    player.stopVideo();
});

$(document).ready(function () {
    stream.show();
});