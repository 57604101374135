var formFuncs = {};

function pickInputs(form, detailed){
    var results = {},
        fields = form.find('.form-group');

    if(fields.length == 0 && form.hasClass('form-group')){
        fields = form;
    }

    if(detailed){
        fields = form.find('input, textarea');
    }

    fields.each(function(){
        var elem        = $(this),
            wrapper     = elem,
            input       = elem.find('input, textarea');

        if(detailed) {
            input = elem;
            wrapper = input.parents('.form-group');
        }

        var checked     = elem.find('input:checked'),
            id          = input.attr('id'),
            type        = input.data('type'),
            val         = input.val(),
            name        = input.attr('name'),
            required    = input.hasClass('required');

        if(!type || type == 'undefined'){
            type = 'text';
        }

        if( type == "phone" ){
            val = unmaskedPhone(val);
        }

        if( type == "select" ){
            val = input.attr('data-id');
        }

        if( type == "checkbox" ){
            if(input.prop("checked")){
                val = 1;
            }else{
                val = 0;
            }            
        }

        if( type == "checkbox" ){
            if(input.prop("checked")){
                val = 1;
            }else{
                val = 0;
            }            
        }

        if( wrapper.hasClass('radio-toggler') ){
            id = checked.attr('id');
            name = checked.attr('name');
            val = checked.val();
        }

        results[name] = {
            id:         id,
            value:      val,
            required:   required,
            type:       type,
        };

    });

    return results
}