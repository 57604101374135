function headerScroll(){
    if($(window).scrollTop()){
        $('header.site-header').addClass('scroll');
    }else{
        $('header.site-header').removeClass('scroll');
    }
}

headerScroll();

$(window).scroll(headerScroll);

$('.toggle-menu').click(function(){
    $('.mobile-menu').stop().fadeToggle(300);
});

$('.close-menu').click(function(){
    $('.mobile-menu').stop().fadeOut(300);
});

$(document).on('click', '.close-image-popup', function(){
    $('.image-popup').fadeOut(300);
})

$(document).on('click', '.article-body img', function(){
    var elem = $(this),
        src = elem.attr('src');

    $('.image-popup').fadeIn(300).find('.image-wrapper').html('<img src="' + src + '">');
})